import React from 'react';
import { styled } from '@glitz/react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { theme } from '../Theme';
import ContentArea from '../Shared/ContentArea/ContentArea';
import BackToArticle from './../Shared/BackToArticle/BackToArticle';
import { media } from '@glitz/core';
import Grid from '../Shared/Grid/Grid';
import { applyEditModeAttr } from '../Shared/Common/Helpers';
import TepeShareDetailPageModel from './TepeShareDetailPageModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { translate } from '../Shared/Common/Translate';

const TepeShareDetailPage = () => {
  const { contentArea, listingPageUrl, inEditMode } = useCurrentPage<
    TepeShareDetailPageModel
  >();

  const {
    translations: {
      'tepeShareDetailPage/backToArticle': backToArticleText,
      'tepeShareDetailPage/backToArticleName': backToArticleName,
    },
  } = useAppSettingsData();

  return (
    <Container>
      <BackToArticle
        title={translate(backToArticleText, [backToArticleName]).join(' ')}
        linkUrl={listingPageUrl}
      />
      <ContentWrapper>
        <Grid
          type={12}
          data-content-area
          {...applyEditModeAttr(inEditMode && 'MainContent')}
        >
          {Array.isArray(contentArea) && (
            <ContentArea childItems={contentArea} />
          )}
        </Grid>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div({
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto' },
  padding: {
    x: theme.spacing(4),
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.none,
    },
  }),
});

const ContentWrapper = styled.div({
  margin: {
    top: theme.spacing(4),
    bottom: theme.spacing(8),
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: {
      top: theme.spacing(8),
      bottom: theme.spacing(16),
    },
  }),
});

export default TepeShareDetailPage;
